<template>
  <b-overlay
    :show="moreInfoLoading"
    spinner-variant="primary"
  >
    <app-breadcrumb
      :page-title="pageTitle"
      :breadcrumb="breadcrumb"
    />
    <list-crud
      :loading="loading"
      :per-page.sync="perPage"
      :per-page-options="perPageOptions"
      :search-query.sync="searchQuery"
      :items="items"
      :table-columns="tableColumns"
      :sort-by.sync="sortBy"
      :is-sort-dir-desc.sync="isSortDirDesc"
      :current-page.sync="currentPage"
      :from="from"
      :to="to"
      :total-items="totalItems"
      hide-search
      @click-btn-create="$router.push({ name: 'custom-model-items.create', params: { entityID: entityID } })"
    >

      <template #cell()="data">
        <template v-if="data.field.type === 'files' || data.field.type === 'images'">
          {{ data.item.data[data.field.key].length }} шт.
        </template>
        <template v-else-if="data.field.type === 'file'">
          <b-button
            v-if="data.item.data[data.field.key]"
            variant="flat-primary"
            class="btn-icon"
            :href="data.item.data[data.field.key].url"
            target="_blank"
          >
            <feather-icon icon="FileIcon" />
          </b-button>
          <template v-else>
            -
          </template>
        </template>
        <template v-else-if="data.field.type === 'image'">
          <b-avatar
            v-if="data.item.data[data.field.key]"
            :src="data.item.data[data.field.key].url"
            square
            size="lg"
          />
          <template v-else>
            -
          </template>
        </template>
        <div v-else>
          {{ data.item.data[data.field.key] }}
        </div>
      </template>

      <template #cell(id)="data">
        <div class="small">
          {{ data.item.id }}
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item :to="{ name: 'custom-model-items.show', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Посмотреть</span>
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'custom-model-items.edit', params: { id: data.item.id } }"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Изменить</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="deleteItem(data.item)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Удалить</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </list-crud>
  </b-overlay>

</template>

<script>
import {
  getCurrentInstance, onMounted, ref, computed,
} from 'vue'
import get from 'lodash/get'
import {
  BCard, BCardBody, BRow, BCol, BLink, BDropdown, BDropdownItem, BOverlay, BButton, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCrudList from '@/composables/useCrudList'
import { getEntityItems, deleteEntityItem } from '@/services/main-api/websites/externals/entity-items'
import { getEntity as getEntityApi } from '@/services/main-api/websites/externals/entities'
import { getEntityFields } from '@/services/main-api/websites/externals/entity-fields'
import { getWebsite } from '@/services/main-api/websites/externals/websites'
import ListCrud from '@/components/crud/ListCrud.vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'

export default {
  name: 'ListView',
  components: {
    ListCrud,
    BCard,
    BCardBody,
    BRow,
    BCol,
    vSelect,
    BLink,
    BDropdown,
    BDropdownItem,
    BOverlay,
    AppBreadcrumb,
    BButton,
    BAvatar,
  },
  props: {
    entityID: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const moreInfoLoading = ref(false)

    const entity = ref(null)

    const getEntity = async () => {
      try {
        const res = await getEntityApi({
          id: props.entityID,
        })
        entity.value = res.data.data
      } catch (e) {
        entity.value = null
      }
    }

    const site = ref(null)

    const getSite = async () => {
      try {
        const res = await getWebsite({
          id: entity.value.website_id,
        })
        site.value = res.data.data
      } catch (e) {
        site.value = null
      }
    }

    const fields = ref([])

    const getFields = async () => {
      try {
        const res = await getEntityFields({
          entityID: props.entityID,
          orderBy: 'sort_order',
          sortedBy: 'asc',
          perPage: 3,
        })
        fields.value = res.data.data
      } catch (e) {
        fields.value = []
      }
    }

    // BEGIN Breadcrumb
    const breadcrumb = computed(() => {
      const bItems = [
        {
          text: 'Сайты',
          to: { name: 'sites' },
        },

      ]

      if (site.value) {
        bItems.push({
          text: get(site.value, 'name', ''),
          to: { name: 'sites.external.show', params: { id: get(site.value, 'id', '') } },
        })
      }

      if (entity.value) {
        bItems.push({
          text: get(entity.value, 'name', ''),
          active: true,
        })
      }

      return bItems
    })
    const pageTitle = computed(() => get(entity.value, 'name', ''))
    // END Breadcrumb

    const getItems = async () => {
      let search = null
      if (instance.searchQuery) {
        search = `${instance.searchQuery}`
      }

      try {
        const res = await getEntityItems({
          entityID: props.entityID,
          perPage: instance.perPage,
          sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
          orderBy: instance.sortBy,
          page: instance.currentPage,
          search,
        })

        instance.items = res.data.data
        instance.currentPage = res.data.meta.current_page
        instance.from = res.data.meta.from
        instance.to = res.data.meta.to
        instance.totalItems = res.data.meta.total
      } catch (e) {
        instance.items = []
      }
    }

    const tableColumns = computed(() => [
      { key: 'id', label: 'ID', sortable: true },
      ...fields.value.map(i => ({
        key: i.key,
        label: i.name,
        type: i.type,
      })),
      { key: 'actions', label: 'Действия' },
    ])

    const {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      items,
      deleteItem,
    } = useCrudList({
      sortBy: 'id',
      isSortDirDesc: true,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'data', label: 'Данные', sortable: true },
        { key: 'actions', label: 'Действия' },
      ],
      getItems,
      getMoreData: async () => {
        await Promise.all([
          (async () => {
            await getEntity()
            await getSite()
          })(),
          getFields(),
        ])
      },
      deleteEntity: item => deleteEntityItem(item.id),
      getQuestionTextBeforeDeletion: item => `Сущность с id = ${item.id} будет удалена`,
      getTextAfterDeletion: () => 'Сущность была удалена.',
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      loading,
      moreInfoLoading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,

      pageTitle,
      breadcrumb,

      get,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
